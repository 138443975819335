<template lang="pug">
div
  cbr-user-filter(:filters="filters" @changed="$emit('filterChanged')")
  portal(to="cm-reserved")
    v-icon(small @click="toggleReserveFilter").copy-arrow mdi-menu-down
    v-dialog(v-model="reservedFilterShowing" :max-width="600")
      v-card
        v-card-title Exam date filters
        v-card-text
          filter-autocomplete(
            v-model="filters.examLocation"
            :getItems="getExamLocation"
            label="Exam Location"
            testName="exam-location"
            clearable
          )
          datepicker(
            v-model="filters.examDateAfter"
            label="Exam date from"
          )
          datepicker(
            v-model="filters.examDateBefore"
            label="Exam date to"
          )
        v-card-actions
          v-spacer
          v-btn(color="error" text @click="toggleReserveFilter") Close
          v-btn(color="success" text @click="submitExamFilter") Apply
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'CourseDayFiltersList',

  props: {
    filters: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    reservedFilterShowing: false
  }),

  methods: {
    toggleReserveFilter() {
      this.reservedFilterShowing = !this.reservedFilterShowing
    },

    submitExamFilter() {
      this.toggleReserveFilter();
      this.$emit('filterChanged')
    },

    ...mapActions({
      getExamLocation: 'crmExamLocation/list',
    }),
  },
  components: {
    datepicker: () => import('@/app/admin/modules/candidates/components/filters/CandidateDatepickerFilters.vue'),
    filterAutocomplete: () => import('@/components/global/FilterAutocomplete.vue'),
    cbrUserFilter: () => import('@/app/admin/modules/course_managment/components/cm_filters/CMFiltersCBRUser.vue')
  },
}
</script>
